
import {Options, prop} from "vue-class-component";
import Vue from "@/extensions/Vue";
import AppHeader from "../components/layout/AppHeader.vue";
import {rootContext} from "@/store";
import _ from "lodash";

class Props {
    onUpdate = prop({
        required: false,
        type: Function
    });
    requestInProgress = prop({
        required: false,
        type: Boolean,
        default: false
    });
}

@Options({
    name: "DefaultLayout",
    components: {AppHeader}
})
export default class DefaultLayout extends Vue.with(Props) {
    declare requestInProgress: boolean;

    mounted(): void {
        this.fillToViewHeight();
    }

    updated(): void {
        this.fillToViewHeight();

        this.$events.emit("update");
    }

    get appIsInitialized(): boolean {
        return rootContext.getters.isInitialized;
    }

    get percentage(): number {
        const modules = rootContext.modules;

        const initializingModules = [
            modules.building.state.isInitialized,
            modules.contract.state.isInitialized,
            modules.job.state.isInitialized,
            modules.meter.state.isInitialized,
            modules.product.state.isInitialized,
            modules.unit.state.isInitialized,
            modules.unitCheck.state.isInitialized,
        ]

        const initializedCount = _.filter(initializingModules, i => i === true).length;

        return Math.round(initializedCount / initializingModules.length * 100);
    }

    fillToViewHeight(): void {
        for (const el of document.querySelectorAll(".fill")) {
            if (!(el instanceof  HTMLElement)) continue;

            const rect = el.getBoundingClientRect();

            const height = window.innerHeight - rect.top;

            if (rect.height > height) return;

            el.style.height = Math.round(height).toString() + "px";
        }
    }
}
