<template>
    <div class="header bg-primary d-flex justify-content-between align-items-center">
        <div class="ms-4">
            <i class="fa fa-long-arrow-alt-left fa-2x text-light" v-if="canGoBack" @click="onBack()" />
            <span class="text-light small fw-bold" v-else>{{ version }}</span>
        </div>
        <h1 class="text-light m-0">{{ title }}</h1>
        <div class="me-4" @click="onLogout()" v-if="$route.name === 'Buildings'">
            <i class="fa fa-door-open fa-2x text-light" />
        </div >
        <div class="me-4" @click="onHome()" v-else>
            <i class="fa fa-home fa-2x text-light" />
        </div>
    </div>
    <template v-if="isOffline">
        <template v-if="offlineModeIsPermitted">
            <el-alert class="offline-alert"
                      title="App is running in offline mode."
                      type="info"
                      :closable="false"
                      v-if="!hasPendingChanges" />
            <el-alert class="offline-alert"
                      :title="`You have ${pendingChangesCount} unsaved changes. re-establish connectivity to save.`"
                      type="warning"
                      :closable="false"
                      v-else-if="hasPendingChanges" />
        </template>
        <template v-else>
            <el-alert class="offline-alert"
                      title="You are offline. An internet connection is required for correct operation."
                      type="error"
                      :closable="false"/>
        </template>
    </template>
    <el-alert class="offline-alert"
              :title="`Saving ${pendingChangesCount} changes...`"
              type="warning"
              :closable="false"
              v-else-if="hasPendingChanges" />
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import {rootContext, userContext} from "@/store";

@Options({
    name: "AppHeader",
})
export default class AppHeader extends Vue {
    logout = userContext.actions.logout;

    version = process.env.VUE_APP_VERSION;

    get canGoBack():boolean {
        return this.$route.name !== "Home" && this.$route.name !== "Buildings";
    }

    get hasPendingChanges(): boolean {
        return this.pendingChangesCount > 0;
    }

    get isOffline(): boolean {
        return rootContext.state.isOffline;
    }
    
    get title(): string {
        return this.$route.meta.title;
    }

    get pendingChangesCount(): number {
        return rootContext.state.commands.length;
    }

    get offlineModeIsPermitted(): boolean {
        return process.env.VUE_APP_PERMIT_OFFLINE_MODE === "true";
    }

    async onBack(): Promise<void> {
        await this.$router.back();
    }

    async onHome(): Promise<void> {
        await this.$router.push({name: "Buildings"});
    }

    async onLogout(): Promise<void> {
        await this.logout();
        await this.$router.push({name: "Login"});
    }
}
</script>

<style lang="scss" scoped>
.fa-door-open {
    font-size: 1.5em;
}

.fa-home {
    font-size: 1.7em;
}

.fa-long-arrow-alt-left {
    font-size: 1.7em;
}

.header {
    height: 50px;
}

h1 {
    font-size: 1.5em;
}
</style>