
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import DefaultLayout from "../layout/DefaultLayout.vue";
import {jobContext} from "@/store";

@Options({
    name: "NewJobOptions",
    components: {DefaultLayout},
})
export default class JobNew extends Vue {
    selectNewJobType = jobContext.actions.selectNewJobType;

    async onSelectType(type: string): Promise<void> {
        await this.selectNewJobType(type);
        await this.$router.push({name: "JobCreate"});
    }
}
