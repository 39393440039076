<template>
    <div class="layout"
         v-loading="requestInProgress"
         element-loading-spinner="el-icon-loading"
         element-loading-text="Loading"
         element-loading-background="rgba(0, 0, 0, 0.8)">
        <header class="sticky-top fixed-top w-100">
            <app-header />
        </header>
        <main class="size-constraint">
            <slot v-if="appIsInitialized" />
            <div class="fill" v-else>
                <div class="h-100 d-flex flex-column justify-content-center align-items-center">
                    <el-progress type="dashboard" :percentage="percentage">
                        <template #default="{ percentage }">
                            <div class="font-large-5 mb-1">{{ percentage }}%</div>
                            <div>Initializing</div>
                        </template>
                    </el-progress>
                </div>
            </div>
        </main>
        <footer>
            <!-- Footer -->
        </footer>
    </div>
</template>

<script lang="ts">
import {Options, prop} from "vue-class-component";
import Vue from "@/extensions/Vue";
import AppHeader from "../components/layout/AppHeader.vue";
import {rootContext} from "@/store";
import _ from "lodash";

class Props {
    onUpdate = prop({
        required: false,
        type: Function
    });
    requestInProgress = prop({
        required: false,
        type: Boolean,
        default: false
    });
}

@Options({
    name: "DefaultLayout",
    components: {AppHeader}
})
export default class DefaultLayout extends Vue.with(Props) {
    declare requestInProgress: boolean;

    mounted(): void {
        this.fillToViewHeight();
    }

    updated(): void {
        this.fillToViewHeight();

        this.$events.emit("update");
    }

    get appIsInitialized(): boolean {
        return rootContext.getters.isInitialized;
    }

    get percentage(): number {
        const modules = rootContext.modules;

        const initializingModules = [
            modules.building.state.isInitialized,
            modules.contract.state.isInitialized,
            modules.job.state.isInitialized,
            modules.meter.state.isInitialized,
            modules.product.state.isInitialized,
            modules.unit.state.isInitialized,
            modules.unitCheck.state.isInitialized,
        ]

        const initializedCount = _.filter(initializingModules, i => i === true).length;

        return Math.round(initializedCount / initializingModules.length * 100);
    }

    fillToViewHeight(): void {
        for (const el of document.querySelectorAll(".fill")) {
            if (!(el instanceof  HTMLElement)) continue;

            const rect = el.getBoundingClientRect();

            const height = window.innerHeight - rect.top;

            if (rect.height > height) return;

            el.style.height = Math.round(height).toString() + "px";
        }
    }
}
</script>

<style lang="scss" scoped>
.layout:deep {
    > .el-loading-mask {
        .el-loading-spinner {
            .el-icon-loading {
                font-size: 3rem;
            }

            .el-loading-text {
                font-size: 1.2em;
            }
        }
    }
}
</style>