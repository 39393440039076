<template>
    <default-layout>
        <div class="p-3 job-new d-flex flex-column justify-content-center">
            <button class="my-2 py-2 btn btn-secondary" @click="onSelectType('cleaning')">CLEANING</button>
            <button class="my-2 py-2 btn btn-secondary" @click="onSelectType('concierge')">CONCIERGE</button>
            <button class="my-2 py-2 btn btn-secondary" @click="onSelectType('handyman')">HANDYMAN</button>
            <button class="my-2 py-2 btn btn-secondary" @click="onSelectType('sales')">SALES</button>
        </div>
    </default-layout>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import DefaultLayout from "../layout/DefaultLayout.vue";
import {jobContext} from "@/store";

@Options({
    name: "NewJobOptions",
    components: {DefaultLayout},
})
export default class JobNew extends Vue {
    selectNewJobType = jobContext.actions.selectNewJobType;

    async onSelectType(type: string): Promise<void> {
        await this.selectNewJobType(type);
        await this.$router.push({name: "JobCreate"});
    }
}
</script>

<style lang="scss" scoped>
.job-new {
    height: 90vh;
}
</style>