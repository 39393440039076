
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import {rootContext, userContext} from "@/store";

@Options({
    name: "AppHeader",
})
export default class AppHeader extends Vue {
    logout = userContext.actions.logout;

    version = process.env.VUE_APP_VERSION;

    get canGoBack():boolean {
        return this.$route.name !== "Home" && this.$route.name !== "Buildings";
    }

    get hasPendingChanges(): boolean {
        return this.pendingChangesCount > 0;
    }

    get isOffline(): boolean {
        return rootContext.state.isOffline;
    }
    
    get title(): string {
        return this.$route.meta.title;
    }

    get pendingChangesCount(): number {
        return rootContext.state.commands.length;
    }

    get offlineModeIsPermitted(): boolean {
        return process.env.VUE_APP_PERMIT_OFFLINE_MODE === "true";
    }

    async onBack(): Promise<void> {
        await this.$router.back();
    }

    async onHome(): Promise<void> {
        await this.$router.push({name: "Buildings"});
    }

    async onLogout(): Promise<void> {
        await this.logout();
        await this.$router.push({name: "Login"});
    }
}
